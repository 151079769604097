<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mb-4 mt-1">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <h4 class="font-weight-bold">Overview</h4>
          <div class="form-group mb-0 vanila-daterangepicker d-flex flex-row">
            <div class="date-icon-set" id="datepicker">
              <date-range-picker
                :date-range="ranges"
                :startDate="fromDate"
                :endDate="toDate"
                :opens="opens"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                @update="updateValues"
                :autoApply="true"
                :alwaysShowCalendars="false"
                class="daterange"
              >
                <template #input="picker">
                  <div class="daterange-textbox">
                    <template v-if="picker.startDate">
                      {{ picker.startDate | date }} -
                      {{ picker.endDate | date }}
                    </template>
                    <template v-else> Select a Date Range </template>
                  </div>
                </template>
              </date-range-picker>

              <span class="search-link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class=""
                  width="20"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <div
                class="d-flex card-body users-card-total"
                title="Number of users who converted"
              >
                <div class="d-flex">
                  <div class="">
                    <p class="mb-2 text-secondary">Active Users</p>
                    <div
                      class="
                        d-flex
                        flex-wrap
                        justify-content-start
                        align-items-center
                      "
                    >
                      <h5 class="mb-0 users-total">
                        {{
                          this.customerBuckets.activeUsers
                            ? this.customerBuckets.activeUsers
                            : "-"
                        }}
                      </h5>
                      <!-- <p class="mb-0 ml-3 text-success font-weight-bold">+3.55%</p> -->
                    </div>
                  </div>
                </div>
                <img :src="activeUser" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card asleep-users-card">
              <div
                class="d-flex card-body users-card-total"
                title="Users who showed intent but did not convert"
              >
                <div class="d-flex align-items-center">
                  <div class="">
                    <p class="mb-2 text-secondary">Asleep Users</p>
                    <div
                      class="
                        d-flex
                        flex-wrap
                        justify-content-start
                        align-items-center
                      "
                    >
                      <h5 class="mb-0 users-total">
                        {{
                          this.customerBuckets.asleepUsers
                            ? this.customerBuckets.asleepUsers
                            : "-"
                        }}
                      </h5>
                      <!-- <p class="mb-0 ml-3 text-danger font-weight-bold">-9.98%</p> -->
                    </div>
                  </div>
                </div>
                <img :src="asleepUser" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card alive-users-card">
              <div
                class="d-flex card-body users-card-total"
                title="Users who responded, but did not show intent / convert"
              >
                <div class="d-flex">
                  <div class="">
                    <p class="mb-2 text-secondary">Alive Users</p>
                    <div
                      class="
                        d-flex
                        flex-wrap
                        justify-content-start
                        align-items-center
                      "
                    >
                      <h5 class="mb-0 users-total">
                        {{
                          this.customerBuckets.aliveUsers
                            ? this.customerBuckets.aliveUsers
                            : "-"
                        }}
                      </h5>
                      <!-- <p class="mb-0 ml-3 text-success font-weight-bold">+2.67%</p> -->
                    </div>
                  </div>
                </div>
                <img :src="aliveUser" />
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card dead-users-card">
              <div
                class="d-flex card-body users-card-total"
                title="Total users till to date minus users who did not respond / show intent / convert in the time frame"
              >
                <div class="d-flex align-items-center">
                  <div class="">
                    <p class="mb-2 text-secondary">Dead Users</p>
                    <div
                      class="
                        d-flex
                        flex-wrap
                        justify-content-start
                        align-items-center
                      "
                    >
                      <h5 class="mb-0 users-total">
                        {{
                          this.customerBuckets.deadUsers
                            ? this.customerBuckets.deadUsers
                            : "-"
                        }}
                      </h5>
                      <!-- <p class="mb-0 ml-3 text-danger font-weight-bold">-9.98%</p> -->
                    </div>
                  </div>
                </div>
                <img :src="deadUser" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        v-for="engagFlows in engagementFlows"
        :key="engagFlows.name"
        class="col-md-3"
      >
        <div class="card abandoned-cart">
          <div class="card-header">
            <h5>{{ formatTitleCase(engagFlows.name) }}</h5>
          </div>
          <div class="card-content card-content-data">
            <div class="d-flex ab-cart-content">
              <div class="d-flex trigger-total">
                <h1>
                  {{
                    engagFlows.statistics.triggers
                      ? engagFlows.statistics.triggers
                      : 0
                  }}
                </h1>
                <p>Total Trigger</p>
              </div>
            </div>
            <div class="table-data">
              <table>
                <tbody>
                  <tr class="ab-cart-details">
                    <td class="ab-cart-name">
                      <div class="d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="circle-icon"
                          fill="rgb(24 157 226)"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="12" cy="12" r="12" />
                        </svg>
                        <p>
                          {{
                            engagFlows.statistics.notified
                              ? "Notified"
                              : "Notified"
                          }}
                        </p>
                      </div>
                    </td>
                    <td class="ab-cart-data">
                      <p class="ab-cart-total">
                        {{
                          engagFlows.statistics.notified
                            ? engagFlows.statistics.notified
                            : 0
                        }}
                      </p>
                    </td>
                  </tr>

                  <tr class="ab-cart-details">
                    <td class="ab-cart-name">
                      <div class="d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="circle-icon"
                          fill="rgb(255, 180, 0)"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="12" cy="12" r="12" />
                        </svg>
                        <p>
                          {{
                            engagFlows.statistics.responded
                              ? "Responded"
                              : "Responded"
                          }}
                        </p>
                      </div>
                    </td>
                    <td class="ab-cart-data">
                      <p class="ab-cart-total">
                        {{
                          engagFlows.statistics.responded
                            ? engagFlows.statistics.responded
                            : 0
                        }}
                      </p>
                    </td>
                  </tr>

                  <tr class="ab-cart-details">
                    <td class="ab-cart-name">
                      <div class="d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="circle-icon"
                          fill="rgb(255, 76, 81)"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="12" cy="12" r="12" />
                        </svg>
                        <p>
                          {{
                            engagFlows.statistics.showedIntent
                              ? "Show Intent"
                              : "Show Intent"
                          }}
                        </p>
                      </div>
                    </td>
                    <td class="ab-cart-data">
                      <p class="ab-cart-total">
                        {{
                          engagFlows.statistics.showedIntent
                            ? engagFlows.statistics.showedIntent
                            : 0
                        }}
                      </p>
                    </td>
                  </tr>

                  <tr class="ab-cart-details">
                    <td class="ab-cart-name">
                      <div class="d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="circle-icon"
                          fill="rgb(145, 85, 253)"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="12" cy="12" r="12" />
                        </svg>
                        <p>
                          {{
                            engagFlows.statistics.incentivised
                              ? "Incentivised"
                              : "Incentivised"
                          }}
                        </p>
                      </div>
                    </td>
                    <td class="ab-cart-data">
                      <p class="ab-cart-total">
                        {{
                          engagFlows.statistics.incentivised
                            ? engagFlows.statistics.incentivised
                            : 0
                        }}
                      </p>
                    </td>
                  </tr>

                  <tr class="ab-cart-details">
                    <td class="ab-cart-name last-row-name">
                      <div class="d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="circle-icon"
                          fill="rgb(86, 202, 0)"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="12" cy="12" r="12" />
                        </svg>
                        <p>
                          {{
                            engagFlows.statistics.converted
                              ? "Converted"
                              : "Converted"
                          }}
                        </p>
                      </div>
                    </td>
                    <td class="ab-cart-data last-row-data">
                      <p class="ab-cart-total">
                        {{
                          engagFlows.statistics.converted
                            ? engagFlows.statistics.converted
                            : 0
                        }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> -->

      <div class="col-lg-9 col-md-12">
        <div class="card card-block card-stretch">
          <div class="card-body p-2">
            <div
              class="
                mb-2
                mt-2
                d-flex
                flex-wrap
                align-items-center
                justify-content-between
              "
            >
              <div class="header-title">
                <h4 class="card-title">Engagement Flow Statistics</h4>
              </div>
            </div>
            <div class="table-responsive table-zoom">
              <table class="table mb-0">
                <thead class="table-color-heading">
                  <tr class="text-secondary">
                    <th scope="col">Name</th>
                    <th scope="col">Triggers</th>
                    <th scope="col">Notified</th>
                    <th scope="col">Responded</th>
                    <th scope="col">Show Intent</th>
                    <th scope="col">Incentivised</th>
                    <th scope="col">Converted</th>
                  </tr>
                </thead>
                <tbody v-if="this.engagementFlows.length > 0">
                  <tr
                    v-for="engagFlows in this.engagementFlows"
                    :key="engagFlows.name"
                    class="white-space-no-wrap"
                  >
                    <td :title="engagFlows.description">
                      {{ formatTitleCase(engagFlows.name) }}
                    </td>
                    <td class="text-center">
                      {{ engagFlows.statistics.triggers }}
                    </td>
                    <td class="text-center">
                      <div>{{ engagFlows.statistics.notified }}</div>
                      <span class="percentage-data"
                        >({{
                          engagFlows.statistics.notified > 0
                            ? Math.round(
                                (engagFlows.statistics.notified /
                                  engagFlows.statistics.triggers) *
                                  100 *
                                  100
                              ) / 100
                            : 0
                        }}%)</span
                      >
                    </td>
                    <td class="text-center">
                      <div>{{ engagFlows.statistics.responded }}</div>
                      <span class="percentage-data"
                        >({{
                          engagFlows.statistics.responded > 0
                            ? Math.round(
                                (engagFlows.statistics.responded /
                                  engagFlows.statistics.triggers) *
                                  100 *
                                  100
                              ) / 100
                            : 0
                        }}%)</span
                      >
                    </td>
                    <td class="text-center">
                      <div>{{ engagFlows.statistics.showedIntent }}</div>
                      <span class="percentage-data"
                        >({{
                          engagFlows.statistics.showedIntent > 0
                            ? Math.round(
                                (engagFlows.statistics.showedIntent /
                                  engagFlows.statistics.triggers) *
                                  100 *
                                  100
                              ) / 100
                            : 0
                        }}%)</span
                      >
                    </td>
                    <td class="text-center">
                      <div>{{ engagFlows.statistics.incentivised }}</div>
                      <span class="percentage-data"
                        >({{
                          engagFlows.statistics.incentivised > 0
                            ? Math.round(
                                (engagFlows.statistics.incentivised /
                                  engagFlows.statistics.triggers) *
                                  100 *
                                  100
                              ) / 100
                            : 0
                        }}%)</span
                      >
                    </td>
                    <td class="text-center">
                      <div>{{ engagFlows.statistics.converted }}</div>
                      <span class="percentage-data"
                        >({{
                          engagFlows.statistics.converted > 0
                            ? Math.round(
                                (engagFlows.statistics.converted /
                                  engagFlows.statistics.triggers) *
                                  100 *
                                  100
                              ) / 100
                            : 0
                        }}%)</span
                      >
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="7" align="center">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page end  -->
  </div>
</template>
  
  <script>
import { DASHBOARD_STATISTICS } from "../../../graphql/query";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Dashboard",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      // fromDate: '2022-05-01',
      // toDate: '2022-07-01',
      startDate: null,
      endDate: null,
      fromDate: null,
      toDate: null,
      customerBuckets: [],
      engagementFlows: [],
      abandonedCart: [],
      engagementFlowsName: "",
      opens: "left", //which way the picker opens, default "center", can be "left"/"right"
      ranges: {
        //default value for ranges object (if you set this to false ranges will no be rendered)
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This month": [moment().startOf("month"), moment().endOf("month")],
        "This year": [moment().startOf("year"), moment().endOf("year")],
        "Last week": [
          moment().subtract(1, "week").startOf("week"),
          moment().subtract(1, "week").endOf("week"),
        ],
        "Last month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
      activeUser: require("../../../assets/images/svg/active-user.svg"),
      asleepUser: require("../../../assets/images/svg/asleep-user.svg"),
      aliveUser: require("../../../assets/images/svg/alive-user.svg"),
      deadUser: require("../../../assets/images/svg/dead-svgrepo.svg"),
    };
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD/MM/Y") : "dd/mm/yyyy";
    },
  },
  mounted() {
    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      localStorage.removeItem("customer-phone");
      localStorage.removeItem("customer-car");
      location.reload();
    }
  },
  methods: {
    updateValues(value) {
      this.fromDate = this.formatDate(value.startDate);
      this.toDate = this.formatDate(value.endDate);
      this.getDashboardData();
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    getDashboardData() {
      if (this.fromDate !== null && this.toDate !== null) {
        this.$apollo
          .query({
            query: DASHBOARD_STATISTICS,
            // fetchPolicy:'no-cache',
            variables: {
              fromDate: this.fromDate,
              toDate: this.toDate,
            },
          })
          .then((data) => {
            this.customerBuckets =
              data.data.statsCustomerEngagement.customerBuckets;

            this.engagementFlows =
              data.data.statsCustomerEngagement.engagementFlows;

            for (var i = 0; i < this.engagementFlows.length; i++) {
              if (this.engagementFlows[i].name === "abandoned cart") {
                this.engagementFlowsName = this.engagementFlows[i].name;
                this.abandonedCart = this.engagementFlows[i].statistics;
              }
            }
          });
      }
    },

    onChangeFromDate(event) {
      this.fromDate = event;
      this.getDashboardData();
    },

    onChangeToDate(event) {
      this.toDate = event;
      this.getDashboardData();
    },
    removeFromDate() {
      this.gte = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.last = null;
      this.first = this.perPage;
      this.customerFilters.filter_date_gte = null;
      this.changeCustomerFilters(this.customerFilters);
      this.getCustomer();
    },
    removeToDate() {
      this.lte = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.last = null;
      this.first = this.perPage;
      this.customerFilters.filter_date_lte = null;
      this.changeCustomerFilters(this.customerFilters);
      this.getCustomer();
    },

    formatTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
};
</script>
  
  <style scoped>
.abandoned-cart .trigger-total {
  flex-direction: column;
}

.trigger-total p {
  margin: 0px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.4px;
  color: rgba(58, 53, 65, 0.68);
}

.abandoned-cart .card-content {
  /* padding: 1.25rem; */
  padding-top: 0px;
}

.card-content-data {
  padding: 1.25rem;
}

.abandoned-cart .ab-cart-content {
  margin-top: 0.6875rem;
  margin-bottom: 1.1875rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.abandoned-cart .ab-cart-details {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0px;
}

.abandoned-cart .ab-cart-name p {
  margin: 0px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
  font-size: 0.875rem;
}

.ab-cart-name {
  padding-left: 0rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  padding: 16px;
  border-bottom: 1px solid rgba(58, 53, 65, 0.12);
}

.last-row-data,
.last-row-name {
  border-bottom: none !important;
}

.ab-cart-details:first-of-type td {
  border-top: 1px solid rgba(58, 53, 65, 0.12);
}

.ab-cart-data {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
  display: table-cell;
  vertical-align: inherit;
  text-align: right;
  padding: 16px;
  flex-direction: row-reverse;
  border-bottom: 1px solid rgba(58, 53, 65, 0.12);
}

.ab-cart-total {
  margin: 0px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
  font-weight: 600;
  font-size: 0.875rem;
}

.table-data {
  width: 100%;
  overflow-x: auto;
  box-shadow: none;
  border-top-color: rgba(58, 53, 65, 0.12);
}
.table-data table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
}

.circle-icon {
  user-select: none;
  display: inline-block;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-right: 0.45rem;
  font-size: 1.05rem;
  color: rgb(86, 202, 0);
}

.active-users-card {
  background: #6bfa74;
}

.users-card-total {
  align-items: center;
  justify-content: space-between;
}

.users-total {
  font-weight: 800;
  font-size: 25px;
}

#datepicker > .vue-daterange-picker {
  width: 13.7rem;
}

.daterange-textbox {
  line-height: 30px !important;
}

.card-header h5 {
  font-size: 1.15rem;
}

.percentage-data {
  font-size: 11px;
  font-weight: 800;
}

.table th,
.table td {
  padding: 15px 18px;
}
</style>